import logo from './avatar.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          See way / Sihui Liang 
        </h1>
       <Link href="https://twitter.com/seeliang_" content="Twitter: @seeliang_"/>
       <Link href="https://au.linkedin.com/in/sihui" content="LinkedIn: sihui"/>
       <Link href="https://github.com/seeliang" content="Github: seeliang"/>
       <Link href="https://seeliang.medium.com" content="Medium: seeliang"/>
       <Link href="https://www.youtube.com/channel/UCQ30t1U3GMzSNIgKjWKMPKw" content="YouTube: seeliang"/>
       <Link href="mailto:hi@sihuiliang.com" content="Email: hi@sihuiliang.com"/>
      </header>
    </div>
  );
}
function Link({href, content}) {
  return (
    <div class="App-link-wrap">
    <a
    className="App-link"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {content}
  </a>
  </div>
  )
}

export default App;
